<template>
  <div class="container">
    <aside-profile/>
    <div v-if="timelineList.length === 0" class="empty-body" v-loading="loading">
        什么？没有文章！这就去写一篇！
    </div>
    <div v-else class="main-box" v-loading="loading">
      <div class="time-line-box">
        <div class="encourage-text">
          嗯..! 目前共计 {{total}} 篇日志。 继续努力。
        </div>
        <div v-for="(item, index) in timelineList" :key="index">
          <div v-if="item.renderYear" class="year-text">
            {{item.renderYear}}
          </div>
          <div class="blog-text">
            <span class="date">{{item.createTime}}</span>
            <router-link :to="`/article/${item.id}`" class="link">{{item.title}}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AsideProfile from '@/components/AsideProfile'
import { listArticle } from '@/api/article'

export default {
  name: 'Archive',
  components: {
    AsideProfile
  },
  data () {
    return {
      pageNo: 1,
      pageSize: 2,
      total: 0,
      // 文章列表
      timelineList: [],
      loading: false
    }
  },
  methods: {
    getArticleList () {
      this.loading = true
      const query = {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      listArticle(query).then(res => {
        this.total = res.data.total
        if (res.data.records.length <= 0) return

        if (this.timelineList.length === 0) {
          // 第一次加载
          this.timelineList = res.data.records
          let renderYear = this.timelineList[0].createTime.substr(0, 4)
          this.timelineList[0].renderYear = renderYear
          this.timelineList[0].createTime = this.timelineList[0].createTime.substr(5, 5)

          for (let i = 1; i < this.timelineList.length; i++) {
            const item = this.timelineList[i]
            if (!item.createTime.startsWith(renderYear)) {
              renderYear = item.createTime.substr(0, 4)
              item.renderYear = renderYear
            }

            // 创建日期只保留月份——日期
            item.createTime = item.createTime.substr(5, 5)
          }
        } else {
          // 取出timelineList最后一个
          let renderYear = this.timelineList[this.timelineList.length - 1].createTime.substr(0, 4)
          const newData = res.data.records
          for (let i = 0; i < newData.length; i++) {
            const item = newData[i]
            if (!item.createTime.startsWith(renderYear)) {
              renderYear = item.createTime.substr(0, 4)
              item.renderYear = renderYear
            }

            // 创建日期只保留月份——日期
            item.createTime = item.createTime.substr(5, 5)
          }
          this.timelineList = this.timelineList.concat(newData)
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created () {
    this.getArticleList()
  },
  mounted () {
    const options = {
      // 表示重叠面积占被观察者的比例，从 0 - 1 取值，
      // 1 表示完全被包含
      threshold: 1.0
    }
    const callback = (entries, observer) => {
      entries.forEach(entry => {
        // entry.time;               // 触发的时间
        // entry.rootBounds;         // 根元素的位置矩形，这种情况下为视窗位置
        // entry.boundingClientRect; // 被观察者的位置举行
        // entry.intersectionRect;   // 重叠区域的位置矩形
        // entry.intersectionRatio;  // 重叠区域占被观察者面积的比例（被观察者不是矩形时也按照矩形计算）
        // entry.target;             // 被观察者
        if (entry.isIntersecting && this.timelineList.length < this.total) {
          this.pageNo++
          this.getArticleList()
        }
      })
    }
    const observer = new IntersectionObserver(callback, options)
    const target = document.querySelector('.footer-bg')
    observer.observe(target)
  }
}
</script>

<style lang="less" scoped>
  .container {
    display: flex;
    justify-content: space-between;

    .empty-body {
      background-color: rgba(255, 255, 255, 0.5);
      width: 770px;
      height: 250px;
      border-radius: 8px;
      color: #000000;
      font-size: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .main-box {
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 8px;
      width: 770px;
      padding: 30px 0;

      .time-line-box {
        position: relative;
        width: 660px;
        margin: 0 auto;

        &::before {
          background: #d9d8d8;
          content: '';
          height: 100%;
          left: -30px;
          margin-left: -2px;
          position: absolute;
          top: 18px;
          width: 4px;
        }

        .encourage-text {
          height: 36px;
          line-height: 36px;
          font-size: 24px;
          margin-bottom: 40px;
          position: relative;

          &::before {
            background: #999;
            border: 1px solid #fff;
            border-radius: 50%;
            content: ' ';
            height: 16px;
            width: 16px;
            left: -33px;
            top: 17px;
            margin-left: -6px;
            margin-top: -4px;
            position: absolute;
          }
        }

        .year-text {
          height: 30px;
          line-height: 30px;
          font-size: 20px;
          margin-bottom: 30px;
          position: relative;

          &::before {
            background: #999;
            border: 1px solid #fff;
            border-radius: 50%;
            content: ' ';
            height: 12px;
            width: 12px;
            left: -31px;
            top: 13px;
            margin-left: -6px;
            margin-top: -4px;
            position: absolute;
          }
        }

        .blog-text {
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          color: #555;
          margin-bottom: 30px;
          position: relative;
          border-bottom: 1px dashed #b4b4b4;
          transform: translateY(0px);

          transition-property: border;
          transition-delay: 0s;
          transition-duration: 0.2s;
          transition-timing-function: ease-in-out;

          .date {
            font-size: 13px;
          }

          .link {
            margin-left: 20px;
          }

          &::before {
            background: #999;
            border: 1px solid #fff;
            border-radius: 50%;
            content: ' ';
            height: 8px;
            width: 8px;
            left: -29px;
            top: 15px;
            margin-left: -6px;
            margin-top: -4px;
            position: absolute;
          }

          a {
            text-decoration: none;
            color: #555555;
          }

          &:hover {
            border-bottom: 1px dashed #1c1c1c;
          }

          &:hover a {
            color: #1c1c1c;
          }

          &:hover::before {
            background: #1c1c1c;
          }
        }
      }

    }
  }
</style>
